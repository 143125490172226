import React, { useState } from "react"
import styled from "styled-components"

const TabContainer = styled.section`
  display: flex;
  flex-direction: row;
  width: fit-content;
  height: 44px;
  border-radius: 1px;
  overflow-x: scroll;
  margin: 20px 0;
  gap: 0.9rem;
  border-bottom: 1px solid #e8e8e8;
  &::-webkit-scrollbar {
    display: none;
  }
`
const TabButton = styled.button`
  height: 100%;
  outline: none;
  cursor: pointer;
  border-bottom: none;
  display: flex;
  flex-direction: column;
  border: none;
  font-size: 14px;
  align-items: center;
  position: relative;
  background: transparent;
  padding: 0;
  border-bottom: ${props =>
    props.active ? "3px solid var(--primary-blue)" : "3px solid transparent"};
  &:focus {
    outline: none;
  }
`
// background: ${props => (props.active ? "#EF7435" : "transparent")};
const Title = styled.span`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: inherit;
  margin: 0px 7px;
  font-size: 14px;
  font-family: ${props =>
    props.active ? "var(--font-semibold)" : "var(--font-regular)"};
  color: ${props => (props.active ? "var(--primary-blue)" : "#000")};
  transition: 0.2s;
  gap: 1rem;
`

export default function TabBarWithRender({ tabs, onTabClick, activeTab }) {
  const handleTabClick = index => {
    onTabClick(index) // Call the callback function with the clicked tab index
  }

  return (
    <>
      <TabContainer>
        {tabs.map((tab, index) => (
          <TabButton
            key={index}
            active={activeTab === index}
            onClick={() => handleTabClick(index)}
          >
            <Title active={activeTab === index}>{tab.title}</Title>
          </TabButton>
        ))}
      </TabContainer>
      {tabs[activeTab].render()}
    </>
  )
}

// import React, { useState, useEffect } from "react"
// import styled from "styled-components"

// const TabContainer = styled.section`
//   display: flex;
//   flex-direction: row;
//   width: 100%;
//   height: 44px;
//   border-radius: 1px;
//   overflow-x: scroll;
//   margin: 23.5px 0;
//   gap: 0.9rem;
//   border-bottom: 1px solid #e8e8e8;
//   &::-webkit-scrollbar {
//     display: none;
//   }
// `

// const TabButton = styled.button`
//   height: 100%;
//   outline: none;
//   cursor: pointer;
//   border-bottom: none;
//   display: flex;
//   flex-direction: column;
//   border: none;
//   font-size: 14px;
//   align-items: center;
//   position: relative;
//   background: transparent;
//   padding: 0;
//   border-bottom: ${props =>
//     props.active ? "3px solid var(--primary-blue)" : "3px solid transparent"};
//   &:focus {
//     outline: none;
//   }
// `

// const Title = styled.span`
//   position: relative;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   height: inherit;
//   margin: 0px 7px;
//   font-size: 14px;
//   font-family: ${props =>
//     props.active ? "var(--font-semibold)" : "var(--font-regular)"};
//   color: ${props => (props.active ? "var(--primary-blue)" : "#000")};
//   transition: 0.2s;
// `

// export default function TabBarWithRender({ tabs }) {
//   const [activeTab, setActiveTab] = useState(
//     () => parseInt(localStorage.getItem("activeTab")) || 0
//   )

//   const handleTabClick = index => {
//     setActiveTab(index)
//     localStorage.setItem("activeTab", index) // Store the active tab index
//   }

//   useEffect(() => {
//     const savedTab = parseInt(localStorage.getItem("activeTab"))
//     if (savedTab) {
//       setActiveTab(savedTab)
//     }
//   }, [])

//   return (
//     <>
//       <TabContainer>
//         {tabs.map((tab, index) => (
//           <TabButton
//             key={index}
//             active={activeTab === index}
//             onClick={() => handleTabClick(index)}
//           >
//             <Title active={activeTab === index}>{tab.title}</Title>
//           </TabButton>
//         ))}
//       </TabContainer>
//       {tabs[activeTab].render()}
//     </>
//   )
// }
