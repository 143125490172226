import './globalStyles/index.css'
import { Provider } from 'react-redux';
import store from './modules/dealer/store/reduxStore';
import Dealers from './routes/Dealers';
import { ToastContainer,toast } from "react-toastify"

function App() {
  return (
    <Provider store={store}>
    <ToastContainer stacked autoClose={3000}/>
    
      <Dealers />
    </Provider> 
  );
}

export default App;
