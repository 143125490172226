import React from "react"

// component
import PhoneInput from "react-phone-input-2"

// css
import "react-phone-input-2/lib/material.css"
import classes from "./PhoneNumInput.module.css"

export default function PhoneNumInput(props) {
  return (
    <PhoneInput
      containerClass={
        props.phoneHasError
          ? `${classes["phone-input__container"]} ${classes["error"]}`
          : `${classes["phone-input__container"]}`
      }
      inputClass={classes["phone-input__input"]}
      country="in"
      countryCodeEditable={false}
      autoFocus={false}
      inputStyle={{
        borderColor: props.phoneHasError && "red",
      }}
      {...props}
    />
  )
}
