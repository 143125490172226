import React, { memo, useState, useEffect } from 'react'
import classes from './addressCheckBox.module.css'
import ModalChekBoxWithLable from '../../../../components/UI/Checkbox/modalCheckBox/ModalChekBoxWithLable'
import { Stack, Typography } from '@mui/material';

const AddressLine = (props) => {
    const { name = "Derekro Berson", address1 = "803, Anna Salai, opposite LIC Building,", address2 = "Anna Salai, Triplicane,", address3 = "Chennai, Tamil Nadu 600002, India.", mobile = "+91 - 85000 95000" } = props;


    return (
        <Stack sx={{ mt: 1 }}>
            <Typography sx={{ fontFamily: "var(--font-bold)", color: "var(--primary-dark)", fontSize: "14px" }} id="modal-modal-title" variant="h6" component="h3">
                {name}
            </Typography>
            <Typography id="modal-modal-description" sx={{ fontFamily: "var(--font-regular)", color: "#68686A", fontSize: "13px" }}>
                {address1}
            </Typography>
            <Typography id="modal-modal-description" sx={{ fontFamily: "var(--font-regular)", color: "#68686A", fontSize: "13px" }}>
                {address2}
            </Typography>
            <Typography id="modal-modal-description" sx={{ fontFamily: "var(--font-regular)", color: "#68686A", fontSize: "13px" }}>
                {address3}
            </Typography>
            <Typography id="modal-modal-description" sx={{ fontFamily: "var(--font-regular)", color: "#68686A", fontSize: "13px", mt: 2 }}>
                {mobile}
            </Typography>
        </Stack>
    )
}

const AddressCheckBoxComp = (props) => {
    const { onChange, manualCheckBox = false, checkVal, checkBoxValue = '', ...addressProps } = props;
    const [isChecked, setisChecked] = useState(props?.checkVal || false);
    useEffect(() => {

        setisChecked(props?.checkVal)


    }, [props?.checkVal])



    const checkBoxChange = (val, checked, setCheckedFn) => {
        onChange?.(val, checked, setCheckedFn);
        setisChecked(checked)
    }
    return (
        <div className={`${classes.biladdress_cont} ${isChecked ? classes.active : ''}`}>
            <ModalChekBoxWithLable
                token={checkBoxValue}
                customCheck={manualCheckBox}
                isCheck={isChecked}
                onChange={checkBoxChange}
                containerStyles={{ alignItems: "flex-start" }}
                lableProps={{
                    component: <AddressLine  {...addressProps} />
                }}
            />
        </div>
    )
}

export default memo(AddressCheckBoxComp)