import { useState, useCallback } from 'react';
import secureLocalStorage from "react-secure-storage"
import { useNavigate } from 'react-router-dom';
import { ToastContainer,toast } from 'react-toastify';

const useApiHttp = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [success, setSuccess] = useState(null);
    const [error, setError] = useState(null);

    const AdminbearerToken = secureLocalStorage.getItem('dealerBearerToken');
    const sendRequest = useCallback(async (requestConfig, applyData = null) => {
        setIsLoading(true);
        setSuccess(null);
        setError(null);
        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_API_URL}/${requestConfig.url}`, {
                method: requestConfig.method ? requestConfig.method : 'GET',
                headers: AdminbearerToken
                    ? {
                        Authorization: `Bearer ${AdminbearerToken}`,
                        "Content-Type": "application/vnd.api+json",
                        "Accept": "application/vnd.api+json",
                    }
                    : {
                        "Content-Type": "application/vnd.api+json",
                        "Accept": "application/vnd.api+json",
                    },
                body: requestConfig.body ? JSON.stringify(requestConfig.body) : null,
            });
            // console.log(response.ok);

            const data = await response.json();
            if (data.status_code === 200 || data.status_code === 201) {
                setSuccess(data.message);
            }
            if (data.status_code !== 200 && data.status_code !== 201) {
                
                throw data;
            }
            applyData && applyData(data);
            // console.log(data);

        } catch (err) {
            
            toast.error((err.message||'Something went wrong!' ), {
                position: "top-center"
              });
            if (err.status_code === 401) {
                secureLocalStorage.removeItem('dealerBearerToken')
                navigate('/login')
            }

            setError(err.message || 'Something went wrong!');
        } finally {
            setIsLoading(false);

        }
    }, [AdminbearerToken]);
    return {
        isLoading,
        success,
        error,
        sendRequest,
    };
};
export default useApiHttp;