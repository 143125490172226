import { useEffect, useState } from "react"
import classes from "./MainLayout.module.css"
import { Outlet, useLocation } from "react-router-dom"
import Header from "../features/Header/Header"
import Footers from "../features/Footer/Footers"
import SubHeader from "../features/Header/SubHeader"
import CustomizedStepper from "../components/UI/Stepper/Stepper"
import { useDispatch, useSelector } from "react-redux"
import Summary from "../features/Summary/Summary"
import { isAuthenticated } from "../services/isAuthenticated"
import { useNavigate } from "react-router-dom"
import useApiHttp from "../hooks/ues-http"
import {
  setProductsData,
  clearAllData,
  setMetaData,
  setSteperVlue,
} from "../services/storeSlice/shopNowSlice"

const tabs = [
  { title: "Home", path: "/" },
  { title: "Shop Now", path: "/shopnow" },
  { title: "My Orders", path: "/myorders" },
  { title: "Raise Complaint", path: "/raisecomplaint" },
]
const steps = ["Shop Now", "Address", "Order Summary"]

const MainLayout = () => {
  const dispatch = useDispatch()
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const setperStatus = useSelector(state => state?.shopNow?.setperStatus)
  const shopNowData = useSelector(state => state.shopNow)

  if (!isAuthenticated()) {
    navigate("/login")
  }

  // if(pathname !== "/shopnow"){
  //   dispatch(clearAllData())
  //   if(setperStatus !== 0){
  //     dispatch(setSteperVlue(0));
  //   }
  // }

  const { isLoading, success, error, sendRequest } = useApiHttp()

  const searchingRequestData = () => {
    sendRequest(
      {
        url: `dealer/shop-now/products`,
      },
      data => {
        dispatch(setProductsData(data?.data))
      }
    )
  }

  useEffect(() => {
    searchingRequestData()
  }, [])

  return (
    <>
      <Header />
      <div className={classes.subheader}>
        <SubHeader tabs={tabs} />
      </div>
      {pathname === "/shopnow" && (
        <div className={classes.main_steper}>
          <div className={classes.steper_box}>
            <CustomizedStepper steps={steps} activeStep={setperStatus} />
          </div>
        </div>
      )}
      <main className={classes.main}>
        <Outlet />
      </main>
      {pathname === "/shopnow" && setperStatus === 0 && <Summary />}
      <Footers className={`${pathname === "/" ? "" : "position"}`} />
    </>
  )
}

export default MainLayout
