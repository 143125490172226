import * as React from "react"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import Select from "@mui/material/Select"
import { styled } from "@mui/system"

import arrow from "../../../assets/image/svg/dropdown.svg"
import { colors } from "@mui/material"

const CustomFormControl = styled(FormControl)({
  "& .MuiOutlinedInput-root": {
    borderRadius: "8px",
    border: "none",
    fontSize: "14px",
    colors: "#363636",
    fontFamily: "var(--font-Medium)",
    "& fieldset": {
      border: "none",
    },
    "&:hover fieldset": {
      border: "none",
    },
    "&.Mui-focused fieldset": {
      border: "none",
    },
  },
})

export default function SelectSmall(props) {
  const { data, title, onChange, value, onClick, width } = props

  return (
    <CustomFormControl sx={{ m: 1, minWidth: width }} size="small">
      <Select
        label={false}
        labelId="demo-select-small-label"
        id="demo-select-small"
        value={value}
        onChange={onChange}
        displayEmpty
        IconComponent={props => (
          <img src={arrow} alt="arrow" style={{ width: "12px" }} />
        )}
        sx={{
          fontFamily: "var(--font-Medium)",
          "& .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
          "&:focus-visible": {
            outline: "none",
          },
        }}
      >
        <MenuItem value="" onClick={onClick}>
          <em>{title}</em>
        </MenuItem>
        {data.map((item, index) => (
          <MenuItem key={index} value={item}>
            {item}
          </MenuItem>
        ))}
      </Select>
    </CustomFormControl>
  )
}
