import React from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import Login from '../modules/dealer/pages/Login/Login';
import MainLayout from '../modules/dealer/layout/MainLayout';
import OtpPage from '../modules/dealer/pages/Login/OtpPage';
import UiTestPage from '../modules/dealer/pages/UiTestPage';
import Home from '../modules/dealer/pages/Home/Home';
import ShopNow from '../modules/dealer/pages/ShopNow/ShopNow';
import MyOrders from '../modules/dealer/pages/MyOrders/MyOrders';
import RaiseComplaint from '../modules/dealer/pages/RaiseComplaint/RaiseComplaint';
import Test from '../modules/dealer/pages/OrderSummary';

const routes = createBrowserRouter([
  {
    path: '/login',
    element: <Login />,
  },
  {
    path: '/otp',
    element: <OtpPage />
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      {
        index: true,
        element: <Home />,  // This is now the index route, no children here
      },
     
      // {
      //   path: '/test',
      //   element: <Test />,  // This is now the index route, no children here
      // },
     
      {
        path: '/shopnow',
        element: <ShopNow />,
      },
      {
        path: '/myorders',
        element: <MyOrders />,
      },
      {
        path: '/raisecomplaint',
        element: <RaiseComplaint />,
      },
      {
        path: '/card',
        element: <UiTestPage />,
      }
    ]
  },
]);

export default function Dealers() {
  return (
    <RouterProvider router={routes} />
  );
}
