import { memo, createRef, useEffect, useRef, useState } from "react"

// Mui
import { TextField, Stack } from "@mui/material"

const otpInputStyle = {
  width: "40px",
  borderRadius: "8px",
  "& .MuiInputBase-input": {
    textAlign: "center",
    height: "40px",
    fontSize: "30px",
    p: 0,
    appearance: "none", // Removes spin buttons
  },
  "& .MuiInputBase-input[placeholder]": {
    fontFamily: "var(--font-semibold)",
    color: "var(--text-color-dark)",
  },
}

const restrictSingleValue = val => {
  return val.target?.value ? val.target?.value.split("").shift() : ""
}

function OtpInputs(props) {
  const boxCount = props.boxCount || 4
  const [otp, setOtp] = useState(Array(boxCount).fill(""))
  const inputRefs = useRef([...Array(boxCount)].map(() => createRef()))

  useEffect(() => {
    inputRefs.current[0].current.focus()
  }, [])

  useEffect(() => {
    props.onSuccess === "true" && setOtp(Array(boxCount).fill(""))
  }, [props.onSuccess])
  const handleKeyDown = (e, i) => {
    if (e.key === "Backspace" && !otp[i] && i > 0) {
      inputRefs.current[i - 1].current.focus()
    } else if (e.key === "ArrowLeft" && i > 0 && otp[i - 1] !== "") {
      inputRefs.current[i - 1].current.focus()
    } else if (e.key === "ArrowRight" && i < boxCount - 1 && otp[i] !== "") {
      inputRefs.current[i + 1].current.focus()
    } else if (e.key === "ArrowUp" || e.key === "ArrowDown") {
      e.preventDefault()
    }
  }
  return (
    <Stack direction="row" justifyContent="center" spacing="30px" {...props}>
      {Array(boxCount)
        .fill(boxCount)
        .map((_, i) => (
          <TextField
            inputRef={inputRefs.current[i]}
            key={i}
            type="number"
            // className={"otp-" + i}
            placeholder="0"
            value={otp[i]}
            onKeyDown={e => handleKeyDown(e, i)}
            onChange={e => {
              e.stopPropagation()
              setOtp(prev =>
                prev.map((val, ind) =>
                  ind === i ? restrictSingleValue(e) : val
                )
              )

              props?.onChange(
                otp.map((val, ind) =>
                  ind === i ? restrictSingleValue(e) : val
                )
              )

              if (e.target.value.length > 0 && i < otp.length - 1) {
                inputRefs.current[i + 1].current.focus()
              }

              if (e.target.value.length === 0 && i > 0) {
                inputRefs.current[i - 1].current.focus()
              }
            }}
            {...props?.inputProps}
            sx={{ ...otpInputStyle, ...props?.inputProps?.sx }}
          />
        ))}
    </Stack>
  )
}

export default memo(OtpInputs)
