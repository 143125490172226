import * as React from "react"
import { Button, Card, CardActions, CardContent, Typography } from "@mui/material"
import styled from "styled-components"

const StatusWidgetLable = styled.div.attrs(props => ({ className: props.class }))`
display:inline-block;
margin-bottom:10px;
height:24px;
font-family:var(--font-bold);
line-height:14px;
font-size:13px;
padding: 4px 10px;
border-radius: 2px;
`

export default function ComplaintCard(props) {
    const { status = "Closed", compDate = "05-Sep-2024", compId = "PO24853" } = props;
    const statusClass = status === "sent" ? 'status_widget_beige' : status === "investigation" ? 'status_widget_purple' : 'status_widget_green'


    return <>
        <Card sx={{ minWidth: 275, maxWidth: 343, boxShadow: "0px 8px 24px rgba(149, 157, 165, .2)" }}>
            <CardContent sx={{ paddingBottom: 0.5 }} >
                <Typography variant="h6" sx={{ fontSize: 16, fontFamily: "var(--font-semibold)" }} component="div">
                    {compId}
                </Typography>
                <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 12, fontFamily: "var(--font-regular)", mb: 0.6 }}>
                    Complaint raised on {compDate}
                </Typography>
                <StatusWidgetLable class={statusClass}>
                    {status}
                </StatusWidgetLable>
                <Typography sx={{ border: "1px solid #EBEBEB", marginTop: "8px" }} component="div"></Typography>
            </CardContent>
            <CardActions sx={{ justifyContent: "center" }}>
                <Button size="small" onClick={() => {
                    if (props?.cta) {
                        props?.cta()
                    }
                }} sx={{ color: 'var(--primary-blue)', fontFamily: "var(--font-semibold)", fontSize: 14 }}>View Complaint</Button>
            </CardActions>
        </Card>
    </>
}
