import { configureStore } from "@reduxjs/toolkit";
import authSlice from "../services/storeSlice/authSlice";
import sopeNowSlice from "../services/storeSlice/shopNowSlice";
import summaryDataSice from "../services/summarySlice/summaryDataSlice";

const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    shopNow: sopeNowSlice,
    summaryData: summaryDataSice,
  },
});

export default store;
