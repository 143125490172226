import React, { useEffect, useState } from "react"
import classes from "./Searching.module.css"
import CustomeSlecter from "../../../components/UI/Dropdown/CustomeSlecter"
import SearchComponent from "../../../components/UI/ShopnowSearch/Search"
import { useDispatch, useSelector } from "react-redux"
import {
  setSearchTerm,
  clearSuggestions,
  clearCategory,
  clearSubCategory,
  setCategory,
  setSubCategory,
} from "../../../services/storeSlice/shopNowSlice"
import useApiHttp from "../../../hooks/ues-http"

const Searching = ({ Loading }) => {
  const dispatch = useDispatch()
  const data = useSelector(state => state.shopNow)

  const [metaData, setMetaData] = useState([])
  // const [selectedCategory, setSelectedCategory] = useState("")
  // const [selectedSubCategory, setSelectedSubCategory] = useState("")
  const [categories, setCategories] = useState([])
  const [subcategories, setSubcategories] = useState([])


  const {
    isLoading: metaDataLoading,
    success: metaDataSucces,
    error: metaDataError,
    sendRequest: metaDataRequest,
  } = useApiHttp()

  useEffect(() => {
    metaDataRequest(
      {
        url: `dealer/shop-now/drop-down`,
      },
      data => {
        setMetaData(data?.data || {}) // Ensure data is safely handled
      }
    )
  }, [])

  useEffect(() => {
    if (metaData?.categories) {
      setCategories(metaData.categories)
    }
  }, [metaData])

  // Handle category change
  const handleCategoryChange = categoryToken => {
    // setSelectedCategory(categoryToken)
    dispatch(setCategory(categoryToken))
    if (categoryToken === " ") {
      dispatch(clearSuggestions())
    }

    const category = categories.find(cat => cat.name === categoryToken)
    if (category) {
      setSubcategories(category.subcategory || [])
    } else {
      setSubcategories([])
    }
    // setSelectedSubCategory("")
  }

  // Handle subcategory change
  const handleSubCategoryChange = subCategoryToken => {
    // setSelectedSubCategory(subCategoryToken)
    dispatch(setSubCategory(subCategoryToken))
    if (subCategoryToken === " ") {
      dispatch(clearSuggestions())
    }
  }

  // Handle search input change
  const handleSearch = term => {
    if (term !== " ") {
      dispatch(setSearchTerm(term))
    }
    if (term === "") {
      dispatch(clearSuggestions())
    }
  }

  const handleCategorySlecter = () => {
    dispatch(clearCategory())
  }
  const handleSubCategorySlecter = () => {
    dispatch(clearSubCategory())
  }

  return (
    <div className={classes.container}>
      <div className={classes.selecter}>
        <CustomeSlecter
          data={
            categories?.length > 0
              ? categories.map(category => ({
                  label: category.name,
                  value: category.token,
                }))
              : [] // Provide an empty array as fallback
          }
          title={"Category"}
          width={150}
          value={data.selectedCategory}
          onChange={e => handleCategoryChange(e.target.value)}
          d
        />
      </div>
      <div className={classes.filters}></div>
      <div className={classes.selecter}>
        <CustomeSlecter
          data={
            subcategories?.length > 0
              ? subcategories.map(sub => ({
                  label: sub.name,
                  value: sub.token,
                }))
              : [] // Provide an empty array as fallback
          }
          title={"Sub-Category"}
          width={180}
          value={data.selectedSubCategory}
          onChange={e => handleSubCategoryChange(e.target.value)}
        />
      </div>
      <div className={classes.filters}></div>
      <div className={`${classes.search_box}`}>
        <SearchComponent
          placeholder="Search"
          fullWidth={true}
          value={data.searchTerm}
          onChange={e => handleSearch(e.target.value)}
        />
      </div>
    </div>
  )
}

export default Searching
