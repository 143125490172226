import React from "react"
import classes from "./Tools.module.css"
import drills from "../../assets/image/jpg/HSS Drills.jpg"
import Reamers from "../../assets/image/jpg/HSS Reamers.jpg"
import endMills from "../../assets/image/jpg/HSS End Mills.jpg"
import Taps from "../../assets/image/jpg/HSS Taps.jpg"
import Cutters from "../../assets/image/jpg/HSS Cutters.jpg"
import ToolBits from "../../assets/image/jpg/HSS Tool Bits.jpg"
import SolidCarbide from "../../assets/image/jpg/Solid Carbide Tools.jpg"
import CarbideTipped from "../../assets/image/jpg/Carbide Tipped Tools.jpg"
import CustomButton from "../../components/UI/Button/Button"
import { useNavigate } from "react-router-dom"
const Tools = () => {
  const navigate = useNavigate()
  return (
    <div className={classes.tools}>
      <div className={classes.tools_box}>
        <h3 className={classes.title}>Tools for a changing world!</h3>
        <p className="sub_titel">
          Explore a wide range of tools from our Product Arsenal
        </p>

        <div className={classes.tools_img}>
          <div className={classes.tools_img_box}>
            <div>
              <img src={drills} alt="Drills" />
            </div>
            <p>HSS Drills</p>
          </div>
          <div className={classes.tools_img_box}>
            <div>
              <img src={Reamers} alt="Drills" />
            </div>
            <p>HSS Reamers</p>
          </div>
          <div className={classes.tools_img_box}>
            <div>
              <img src={endMills} alt="Drills" />
            </div>
            <p>HSS End Mills</p>
          </div>
          <div className={classes.tools_img_box}>
            <div>
              <img src={Taps} alt="Drills" />
            </div>
            <p>HSS Taps</p>
          </div>
          <div className={classes.tools_img_box}>
            <div>
              <img src={Cutters} alt="Drills" />
            </div>
            <p>HSS Cutters</p>
          </div>
          <div className={classes.tools_img_box}>
            <div>
              <img src={ToolBits} alt="Drills" />
            </div>
            <p>HSS Tool Bits</p>
          </div>
          <div className={classes.tools_img_box}>
            <div>
              <img src={SolidCarbide} alt="Drills" />
            </div>
            <p>Solid Carbide Tools</p>
          </div>
          <div className={classes.tools_img_box}>
            <div>
              <img src={CarbideTipped} alt="Drills" />
            </div>
            <p>Carbide Tipped Tools</p>
          </div>
        </div>
        <div className={classes.btn}>
          <CustomButton
            variant="contained"
            customColor="white"
            customBgColor="#32349b"
            custmstyle={{
              padding: "10px 7px",
              width: "200px",
              borderRadius: "5px",
            }}
            onClick={() => navigate("/shopnow")}
          >
            Explore and Order
          </CustomButton>
        </div>
      </div>
    </div>
  )
}

export default Tools
