import React from 'react'
import OrderCard from '../components/UI/Card/OrderCard'
import ComplaintCard from '../components/UI/Card/ComplaintCard'
import ComplaintModal from '../components/UI/Modal/ComplaintModal'
import { useState } from 'react'
import GetAddress from '../features/ShopNow/Address/GetAddress'

const UiTestPage = () => {
    const [modal, setmodal] = useState(false)
    return (
        <div>
            {/* <OrderCard /> */}
            {/* <ComplaintCard /> */}
            {/* <button onClick={() => setmodal((curr) => !curr)}>Click</button>
            <ComplaintModal modalHandle={setmodal} open={modal} /> */}
            <GetAddress />
        </div>
    )
}

export default UiTestPage