import { Stack, Typography } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import CustomButton from "../components/UI/Button/Button"
import { setSteperVlue } from "../services/storeSlice/shopNowSlice"
import { useNavigate } from "react-router-dom"

const OrderSummary = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const arr = ["", "", ""]
  const summaryData = useSelector(state => state.summaryData)
  const summaryData1 = summaryData.updatesummarydata

  return (
    <div style={{ paddingTop: "24px", paddingBottom: "100px" }}>
      <Stack direction={"row"} gap={"20px"}>
        <Stack style={{ width: "100%" }} direction={"column"} gap={"24px"}>
          {/* div order */}
          <Stack
            direction={"row"}
            gap={"16px"}
            style={{
              borderRadius: "8px",
              borderColor: "#E8E8E8",
              backgroundColor: "#FFFFFF",
              alignItems: "center",
              padding: "30px 24px",
            }}
          >
            <SuccessSvg />
            <Stack>
              <Typography
                style={{
                  fontSize: "28px",
                  fontWeight: "700",
                  color: "#000000",
                }}
              >
                Order Placed Successfully!
              </Typography>
              <Typography
                style={{
                  fontSize: "15px",
                  fontWeight: "400",
                }}
              >
                Your order was successful and your purchase order ID is
                <span style={{ color: "#050505" }}>
                  {" "}
                  {summaryData1?.order_id}.
                </span>
              </Typography>
            </Stack>
          </Stack>
          {/* div detail */}
          <Stack
            direction={"column"}
            style={{
              borderRadius: "8px",
              borderColor: "#E8E8E8",
              backgroundColor: "#FFFFFF",
              padding: "24px",
              paddingBottom: "42px",
              alignContent: "start",
            }}
            gap={"8px"}
          >
            <Typography
              style={{ fontSize: "24px", fontWeight: "700", color: "#000000" }}
            >
              {summaryData1?.order_id}
            </Typography>
            <Typography style={{ fontSize: "14px", fontWeight: "400" }}>
              PO Date:{" "}
              <span style={{ fontWeight: "600" }}>
                {" "}
                {summaryData1?.created_at}
              </span>
            </Typography>
            <Stack direction={"row"}>
              <Stack direction={"column"} gap={"24px"} style={{ width: "50%" }}>
                <Stack>
                  <Typography
                    style={{
                      fontSize: "12px",
                      fontWeight: "400",
                      color: "#68686A",
                    }}
                  >
                    Shipping Address
                  </Typography>
                  <Typography
                    style={{
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    {summaryData1?.shipping_address?.address_line_1},<br />
                    {summaryData1?.shipping_address?.address_line_2},<br />
                    {summaryData1?.shipping_address?.city},{" "}
                    {summaryData1?.shipping_address?.state}{" "}
                    {summaryData1?.shipping_address?.pincode},{" "}
                    {summaryData1?.shipping_address?.country}.
                  </Typography>
                </Stack>
                <Typography
                  style={{
                    fontSize: "14px",
                    fontWeight: "400",
                  }}
                >
                  + {summaryData1?.shipping_address?.country_code} -{" "}
                  {summaryData1?.shipping_address?.mobile_number}
                </Typography>
                <Stack>
                  <Typography
                    style={{
                      fontSize: "12px",
                      fontWeight: "400",
                      color: "#68686A",
                    }}
                  >
                    Estimated Delivered Date
                  </Typography>
                  <Typography
                    style={{
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    {summaryData1?.estimate_delivery_date}
                  </Typography>
                </Stack>
              </Stack>
              <Stack direction={"column"} gap={"24px"} style={{ width: "50%" }}>
                <Stack>
                  <Typography
                    style={{
                      fontSize: "12px",
                      fontWeight: "400",
                      color: "#68686A",
                    }}
                  >
                    Billing Address
                  </Typography>
                  <Typography
                    style={{
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    {summaryData1?.billing_address?.address_line_1},<br />
                    {summaryData1?.billing_address?.address_line_2},<br />
                    {summaryData1?.billing_address?.city},{" "}
                    {summaryData1?.billing_address?.state}{" "}
                    {summaryData1?.billing_address?.pincode},{" "}
                    {summaryData1?.billing_address?.country}.
                  </Typography>
                </Stack>
                <Typography
                  style={{
                    fontSize: "14px",
                    fontWeight: "400",
                  }}
                >
                  + {summaryData1?.billing_address?.country_code} -{" "}
                  {summaryData1?.billing_address?.mobile_number}
                </Typography>
                <Stack>
                  <Typography
                    style={{
                      fontSize: "12px",
                      fontWeight: "400",
                      color: "#68686A",
                    }}
                  >
                    Estimated Delivered Date
                  </Typography>
                  <Typography
                    style={{
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    {summaryData1?.estimate_delivery_date}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
          {/* div summary */}
          <Stack
            direction={"column"}
            style={{
              borderRadius: "8px",
              borderColor: "#E8E8E8",
              backgroundColor: "#FFFFFF",
              padding: "24px",
              paddingBottom: "42px",
              alignContent: "start",
            }}
          >
            <Stack
              style={{
                borderBottom: "1px solid #E8E8E8",
                paddingBottom: "20px",
              }}
              gap={"10px"}
              direction={"column"}
            >
              <Typography
                style={{
                  fontSize: "16px",
                  fontWeight: "600",
                  marginTop: "2px",
                }}
              >
                Order Summary
              </Typography>
              <Stack
                direction={"row"}
                style={{ justifyContent: "space-between" }}
              >
                <Typography style={{ fontSize: "14px", fontWeight: "400" }}>
                  Order Value
                </Typography>
                <Typography style={{ fontSize: "14px", fontWeight: "400" }}>
                  ₹ {summaryData1?.order_value ? (Number(summaryData1?.order_value)).toLocaleString('en-IN') : 0}
                </Typography>
              </Stack>
              <Stack
                direction={"row"}
                style={{ justifyContent: "space-between" }}
              >
                <Typography style={{ fontSize: "14px", fontWeight: "400" }}>
                  Total Tax ({summaryData1?.tax_percentage}%)
                </Typography>
                <Typography style={{ fontSize: "14px", fontWeight: "400" }}>
                  ₹ {summaryData1?.order_tax_amount ? (Number(summaryData1?.order_tax_amount)).toLocaleString('en-IN'):0}
                </Typography>
              </Stack>
            </Stack>
            <Stack
              direction={"row"}
              style={{ justifyContent: "space-between", marginTop: "24px" }}
            >
              <Typography
                style={{
                  fontSize: "16px",
                  fontWeight: "700",
                  color: "#000000",
                }}
              >
                Total Amount
              </Typography>
              <Typography
                style={{
                  fontSize: "16px",
                  fontWeight: "700",
                  color: "#65A547",
                }}
              >
                ₹ {summaryData1?.item_total_value ? (Number(summaryData1?.item_total_value)).toLocaleString('en-IN'):0}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
        <Stack
          style={{
            width: "100%",
            borderRadius: "8px",
            borderColor: "#E8E8E8",
            backgroundColor: "#FFFFFF",
            padding: "24px",
            maxHeight: "747px",
            overflow: "hidden",
            overflowY: "scroll",
            alignSelf: "start",
          }}
        >
          <Typography style={{ fontSize: "16px", fontWeight: "600" }}>
            Product Details
            {summaryData1?.products?.map(item => (
              <Stack
                direction={"row"}
                style={{
                  justifyContent: "space-between",
                  borderBottom: "1px solid #E8E8E8",
                  paddingBlock: "24px",
                }}
              >
                <Stack direction={"column"} gap={"10px"}>
                  <Stack>
                    <Typography
                      style={{
                        fontSize: "16px",
                        fontWeight: "600",
                        color: "#000000",
                      }}
                    >
                      {item?.product_code}
                    </Typography>
                    <Typography
                      style={{
                        fontSize: "14px",
                        fontWeight: "400",
                        color: "#000000",
                      }}
                    >
                      {item?.product_name}
                    </Typography>
                  </Stack>
                  <Stack direction={"row"} gap={"8px"}>
                    <Typography
                      variant="span"
                      sx={{
                        backgroundColor: "#EFEFEF",
                        borderRadius: "4px",
                        padding: "4px 6px",
                        fontSize: "12px",
                        fontWeight: "500",
                        color: "#000000",
                      }}
                    >
                      Material: {item?.material}
                    </Typography>
                    <Typography
                      variant="span"
                      sx={{
                        backgroundColor: "#EFEFEF",
                        borderRadius: "4px",
                        padding: "4px 6px",
                        fontSize: "12px",
                        fontWeight: "500",
                        color: "#000000",
                      }}
                    >
                      Finish: {item?.coating} COATING
                    </Typography>
                  </Stack>
                  <Stack
                    direction={"row"}
                    gap={"45px"}
                    style={{ marginTop: "6px" }}
                  >
                    <Typography
                      style={{
                        color: "#68686A",
                        fontSize: "14px",
                        fontWeight: "500",
                      }}
                    >
                      Qty:{" "}
                      <span style={{ color: "#000000" }}>{item?.quantity}</span>{" "}
                      pcs
                    </Typography>
                    <Typography
                      style={{
                        color: "#68686A",
                        fontSize: "14px",
                        fontWeight: "500",
                      }}
                    >
                      Needed Date:{" "}
                      <span style={{ color: "#000000" }}>
                        {item?.requested_date}
                      </span>
                    </Typography>
                  </Stack>
                </Stack>
                <Stack direction={"column"} style={{ alignItems: "end" }}>
                  <Typography
                    style={{
                      fontSize: "20px",
                      fontWeight: "600",
                      color: "#000000",
                    }}
                  >
                    ₹ {item?.product_total_amount ? (Number(item?.product_total_amount)).toLocaleString('en-IN'): 0}
                  </Typography>
                  <Typography
                    style={{
                      fontSize: "16px",
                      fontWeight: "400",
                      color: "#979797",
                      textDecoration: "line-through",
                    }}
                  >
                    ₹ {item?.product_rate_mrp ? (Number(item?.product_rate_mrp)).toLocaleString('en-IN'):0}
                  </Typography>
                </Stack>
              </Stack>
            ))}
          </Typography>
        </Stack>
      </Stack>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <CustomButton
          variant="contained"
          customColor="white"
          customBgColor="#32349b"
          custmstyle={{
            padding: "10px 7px",
            width: "400px",
            marginTop: "20px",
            borderRadius: "8px",
          }}
          onClick={() => {
            navigate("/")
            dispatch(setSteperVlue(0))
          }}
        >
          Go to Home
        </CustomButton>
      </div>
    </div>
  )
}
export default OrderSummary

const SuccessSvg = () => {
  return (
    <svg
      width="58px"
      height="58px"
      viewBox="0 0 120 120"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>web/md/svg/success popup</title>
      <g
        id="web/md/svg/success-popup"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <g
          id="Group"
          transform="translate(10, 10)"
          fill="#65A547"
          fill-rule="nonzero"
        >
          <path
            d="M50,0 C22.5,0 0,22.5 0,50 C0,77.5 22.5,100 50,100 C77.5,100 100,77.5 100,50 C100,22.5 77.5,0 50,0 L50,0 Z M40,75 L15,50 L22,43 L40,61 L78,23 L85,30 L40,75 L40,75 Z"
            id="Shape"
          ></path>
        </g>
      </g>
    </svg>
  )
}
