import React from "react"
import classes from "./Banner.module.css"
import logo from "../../assets/image/png/logo 150yrs@2x.png"
import banerImage from "../../assets/image/png/bannerimage.png"
import CustomButton from "../../components/UI/Button/Button"
import { useNavigate } from "react-router-dom"
const Banner = () => {
  const navigate = useNavigate()
  return (
    <div className={classes.banner}>
      <div className={classes.container}>
        <div className={classes.container_left}>
          <img src={logo} alt="logo" />
          <h3>
            Welcome <span className="staroge">Derekro Berson!</span>
          </h3>
          <p>
            Easily search and navigate with a single click using our intelligent
            search feature.
          </p>
          <CustomButton
            variant="contained"
            customColor="white"
            customBgColor="#32349b"
            custmstyle={{
              padding: "10px 7px",
              width: "200px",
              marginTop: "20px",
              borderRadius: "8px",
            }}
            onClick={() => navigate("/shopnow")}
          >
            Explore and Order
          </CustomButton>
          <div className={classes.contant_list}>
            <p className={classes.contant_titel}>Recent Searches</p>
            <div className={classes.list_align}>
              <ul>
                <li>{"1) 103110019"}</li>
                <li>{"2) M2 HSS SFC 125mm D x 10mm W x 27mm  B "}</li>
                <li>{"3) 103113201"}</li>
                <li>{"4)  M2 HSS SFC 100mm"}</li>
              </ul>
            </div>
          </div>
        </div>
        <div className={classes.container_right}>
          <img src={banerImage} alt="banerImage" />
        </div>
      </div>
    </div>
  )
}

export default Banner
