


import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';
import Fuse from "fuse.js";
import secureLocalStorage from "react-secure-storage";
import { toast } from "react-toastify";
export const fetchList = createAsyncThunk('data/fetchList', async () => {
    const AdminbearerToken = secureLocalStorage.getItem('dealerBearerToken');
 
    const response = await axios.get(`${process.env.REACT_APP_BASE_API_URL}/dealer/shop-now/cart/list`, {
        headers: AdminbearerToken
            ? {
                Authorization: `Bearer ${AdminbearerToken}`,
                "Content-Type": "application/vnd.api+json",
                "Accept": "application/vnd.api+json",
            }
            : {
                "Content-Type": "application/vnd.api+json",
                "Accept": "application/vnd.api+json",
            }
    });

    return response.data.data;
});
export const fetchRemoveList = createAsyncThunk('data/fetchRemoveList', async (requestConfig) => {
const AdminbearerToken = secureLocalStorage.getItem('dealerBearerToken');

    console.log("requestConfig", requestConfig);

    const response = await axios.post(`${process.env.REACT_APP_BASE_API_URL}/dealer/shop-now/cart/remove-product`,
  
        requestConfig.body,  // This is the data you want to send in the POST request
        {
            headers: AdminbearerToken
                ? {
                    Authorization: `Bearer ${AdminbearerToken}`,
                    "Content-Type": "application/vnd.api+json",
                    "Accept": "application/vnd.api+json",
                }
                : {
                    "Content-Type": "application/vnd.api+json",
                    "Accept": "application/vnd.api+json",
                }
        }
    );

    return response.data.data;
});

export const updateQtyDate = createAsyncThunk(
    "data/updateQtyDate",
    async (requestConfig) => {
const AdminbearerToken = secureLocalStorage.getItem('dealerBearerToken');

        const response = await axios.put(
            `${process.env.REACT_APP_BASE_API_URL}/dealer/shop-now/cart/update`,

            requestConfig.body, // This is the data you want to send in the POST request
            {
                headers: AdminbearerToken
                    ? {
                        Authorization: `Bearer ${AdminbearerToken}`,
                        "Content-Type": "application/vnd.api+json",
                        Accept: "application/vnd.api+json",
                    }
                    : {
                        "Content-Type": "application/vnd.api+json",
                        Accept: "application/vnd.api+json",
                    },
            }
        );

        return response.data.data;
    }
);
// Options for Fuse.js
const options = {
    keys: [
        "delimitter",
        "product_code",
        "name",
        "category",
        "category_token",
        "sub_category",
        "coating",
        "type",
        "size",
        "material",
        "item_rate",
        "mrp",
        "moq",
    ],
    threshold: 0.3,   // Adjust this if stricter matching is acceptable
    minMatchCharLength: 2,  // Ignore short search terms
    distance: 100,  // Control how far matches are allowed
    ignoreLocation: true,  // Speed up search by ignoring location-based matches
};

const options2 = {
    keys: ["product_code"], // Keys you want to search on
    threshold: 0.3,   // Adjust this if stricter matching is acceptable
    minMatchCharLength: 2,  // Ignore short search terms
    distance: 100,  // Control how far matches are allowed
    ignoreLocation: true,  // Speed up search by ignoring location-based matches
};

// Options for Fuse.js
const options3 = {
    keys: [
        "category",
        "sub_category",
        "coating",
        "material",
        "delimitter",
    ], // Keys you want to search on
    threshold: 0.3,   // Adjust this if stricter matching is acceptable
    minMatchCharLength: 3,  // Ignore short search terms
    distance: 100,  // Control how far matches are allowed
    ignoreLocation: true,  // Speed up search by ignoring location-based matches
};

const initialState = {
    products: [], // Product data will be fetched
    metaData: [],
    filteredProducts: [],
    searchTerm: "",
    selectedCategory: "",
    selectedSubCategory: "",
    slectmaterial: "",
    slectfinish: "",
    suggestions: [],
    cartData: {},
    resultsTotal: 0,
    listApiStatus:"idle",
    listApiError: null,
    removeListStatus: "idle",
    searchbyitemcode: "",
    searchByAllData: "",
    updateQtyDateStatus:"idle",
    setperStatus: 0,
};

const shopNowSlice = createSlice({
    name: "shopnow",
    initialState,
    reducers: {
        setMetaData(state, action) {
            state.metaData = action.payload;
        },
        setProductsData(state, action) {
            state.products = action.payload;
        },

        setSearchTerm(state, action) {
            state.searchTerm = action.payload;
            shopNowSlice.caseReducers.applyFilters(state); // Apply filters after setting the term
        },
        setCategory(state, action) {
            state.selectedCategory = action.payload;
            shopNowSlice.caseReducers.applyFilters(state); // Apply filters after selecting category
        },
        setSubCategory(state, action) {
            state.selectedSubCategory = action.payload;
            shopNowSlice.caseReducers.applyFilters(state); // Apply filters after selecting sub-category
        },
        setMaterialValue(state, action) {
            state.slectmaterial = action.payload;
            shopNowSlice.caseReducers.applyFilters(state); // Apply filters after selecting material
        },
        setFinish(state, action) {
            state.slectfinish = action.payload;
            shopNowSlice.caseReducers.applyFilters(state); // Apply filters after selecting finish
        },
        setCartData(state, action) {
            state.cartData = action.payload;
        },
        searchByItemCode(state, action) {
            state.searchbyitemcode = action.payload
        shopNowSlice.caseReducers.applyFilters(state); // Apply filters after selecting finish
        },
        searchByAllDats(state, action) {
            state.searchByAllData = action.payload
            shopNowSlice.caseReducers.applyFilters(state);
        },
        setSteperVlue(state, action){
            state.setperStatus = action.payload
        },
        applyFilters(state) {
            let results = state.products; // Start with the full list of products

            // Fuse.js search for search term
            if (state.searchTerm) {
                // const fuse = new Fuse(state.products, options);
                const fuseIndex = Fuse.createIndex(options.keys, state.products);
                const fuse = new Fuse(state.products, options, fuseIndex);

                results = fuse.search(state.searchTerm).map((result) => result.item);
            }
    
            if (state.searchbyitemcode) {
                const fuse = new Fuse(state.products, options2);
                results = fuse.search(state.searchbyitemcode).map((result) => result.item);
            }
            if (state.searchByAllData) {
                const fuse = new Fuse(state.products, options);
                results = fuse.search(state.searchByAllData).map((result) => result.item);
            }

            // Filter by category
            if (state.selectedCategory) {
                results = results.filter(
                    (product) => product.category === state.selectedCategory
                );
            }

            // Filter by subcategory
            if (state.selectedSubCategory) {
                results = results.filter(
                    (product) => product.sub_category === state.selectedSubCategory
                );
            }

            // Filter by material
            if (state.slectmaterial) {
                results = results.filter(
                    (product) => product.material === state.slectmaterial
                );
            }

            // Filter by finish
            if (state.slectfinish) {
                results = results.filter((product) => product.coating === state.slectfinish);
            }

            state.resultsTotal = results.length;
            state.suggestions = results.slice(0, 600); // Limit suggestions to top 10
                // .slice(0, 10);

            console.log("Filtered results:", results); // Debugging output
        },
        clearSuggestions(state) {
            state.suggestions = [];
        },
        clearCategory(state) {
            state.selectedCategory = "";
        },
        clearSubCategory(state) {
            state.selectedSubCategory = "";
        },
        clearAllData(state) {
            state.searchTerm = "";
            state.selectedCategory = "";
            state.selectedSubCategory = "";
            state.suggestions = [];
        },
        updateCartItems(state, action) {
            state.cartData.cart_items[action.payload.index] = action.payload.value;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchList.pending, (state) => {
                state.listApiStatus = 'loading';
            })
            .addCase(fetchList.fulfilled, (state, action) => {
                state.listApiStatus = 'succeeded';
                state.cartData = action.payload;
            })
            .addCase(fetchList.rejected, (state, action) => {
                state.listApiStatus = 'failed';
                state.listApiError = action.error.message;
                toast.error((action.error.message||'Something went wrong!' ), {
                    position: "top-center"
                  });
            })
            .addCase(fetchRemoveList.pending, (state) => {
                state.removeListStatus = 'loading';
            })
            .addCase(fetchRemoveList.fulfilled, (state, action) => {
                state.removeListStatus = 'succeeded';
            
            })
            .addCase(fetchRemoveList.rejected, (state, action) => {
                state.removeListStatus = 'failed';
                toast.error((action.error.message||'Something went wrong!' ), {
                    position: "top-center"
                  });
             
            })
            .addCase(updateQtyDate.pending, (state) => {
                state.updateQtyDateStatus = "loading";
            })
            .addCase(updateQtyDate.fulfilled, (state, action) => {
                state.updateQtyDateStatus = "succeeded";
            })
            .addCase(updateQtyDate.rejected, (state, action) => {
                state.updateQtyDateStatus = "failed";
                toast.error((action.error.message||'Something went wrong!' ), {
                    position: "top-center"
                  });
            });
         
        }
});



// Export actions
export const {
    setProductsData,
    setSearchTerm,
    setCategory,
    setSubCategory,
    clearSuggestions,
    setCartData,
    removeFromCart,
    clearAllData,
    clearCategory,
    clearSubCategory,
    setMetaData,
    setMaterialValue,
    setFinish,
    searchByItemCode,
    searchByAllDats,
    updateCartItems,
    setSteperVlue
} = shopNowSlice.actions;

// Export reducer
export default shopNowSlice.reducer;