import React, { useEffect, useMemo, useState } from "react"
import classes from "./Suggestions.module.css"
import TickIcon from "../../../components/UI/svgComponents/TickIcon"
import remove from "../../../assets/image/svg/remove.svg"
import addcart from "../../../assets/image/svg/add to cart.svg"
import { Paper, List, ListItem } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import Selecter from "../../../components/UI/Dropdown/Select"
import {
  setSearchTerm,
  setCartData,
  clearSuggestions,
  removeFromCart,
  clearAllData,
  setMaterialValue,
  setFinish,
  fetchList,
  fetchRemoveList,
} from "../../../services/storeSlice/shopNowSlice"
import useApiHttp from "../../../hooks/ues-http"
import CustomeSlecter from "../../../components/UI/Dropdown/CustomeSlecter"

const Suggestions = ({ data }) => {
  
  const dispatch = useDispatch()
  const summaryData = useSelector(state => state.shopNow)
  let cartData = summaryData?.cartData?.cart_items
  const [metaData, setMetaData] = useState()
  const [materials, setMaterials] = useState([])
  const [coatings, setMCoatings] = useState([])

  const {
    isLoading: addCardDataLoading,
    success: addCardDataSucces,
    error: addCardDataError,
    sendRequest: addCardDataRequest,
  } = useApiHttp()

  const {
    isLoading: ListDataLoading,
    success: ListDataSucces,
    error: ListDataError,
    sendRequest: ListDataRequest,
  } = useApiHttp()

  const addCardHandleSelect = token => {
    
    addCardDataRequest(
      {
        url: `dealer/shop-now/cart/add`,
        method: "POST",
        body: {
          product_token: token,
        },
      },
      data => {
        dispatch(fetchList())

      }
    )
  }

  const {
    isLoading: metaDataLoading,
    success: metaDataSucces,
    error: metaDataError,
    sendRequest: metaDataRequest,
  } = useApiHttp()

  useEffect(() => {
    metaDataRequest(
      {
        url: `dealer/shop-now/drop-down`,
      },
      data => {
        setMetaData(data?.data || {}) // Ensure data is safely handled
      }
    )
  }, [])
  

  useEffect(() => {
    if (metaData?.materials) {
      setMaterials(metaData.materials)
    }
    if (metaData?.coatings) {
      setMCoatings(metaData.coatings)
    }
  }, [metaData])

  // Handle subcategory change
  const handleMaterialChange = material => {
    // setSelectedSubCategory(subCategoryToken)
    dispatch(setMaterialValue(material))
  }
  // Handle subcategory change
  const handleSubFinishChange = finish => {
    // setSelectedSubCategory(subCategoryToken)
    dispatch(setFinish(finish))
  }

  const removeCartIteme = token => {
    dispatch(fetchRemoveList({ body: { cart_item_token: token } })).unwrap().then(()=>{
      dispatch(fetchList())
    })
  }

  // Memoized list rendering
  const renderedSuggestions = useMemo(() => {
    return data.map((item, index) => (
      <ListItem
        key={index}
        button
        sx={{ borderBottom: "1px solid var(--light-outline)" }}
      >
        <div className={classes.box}>
          <div className={classes.box_left}>
            <h3>{item?.product_code}</h3>
            <p>{`${item?.name} | ${item?.category} | ${item?.sub_category} | ${item?.coating} | ${item.material}`}</p>
          </div>
          <div className={classes.box_right}>
            <label
              className={`label_widgets ${
                item.stock_status === 1 ? "in_stock" : "out_stock"
              }`}
            >
              {item.stock_status === 1 ? "In Stock" : "Out of Stock"}
            </label>
            <label className="rate_box">
              <span className="count">₹ {item.item_rate}</span>
              <span className="line_through_price">₹ {item.mrp}</span>
            </label>

            {cartData.find(x => x.product_token === item.token) ? (
              <>
                <span className="added_cart">
                  <TickIcon />
                   Added to Cart
                </span>
                <span
                  className="cursor iconaddcart"
                  aria-label="Remove from cart"
                  onClick={() => removeCartIteme(cartData.find(x=>x.product_token === item.token)?.cart_item_token)}
                >
                  <img src={remove} alt="Remove from cart" />
                </span>
              </>
            ) 
            : 
            (
              <span
              className="cursor iconaddcart"
              aria-label="Add to cart"
              onClick={() => addCardHandleSelect(item.token)}
            >
              <img src={addcart} alt="Add to cart" />
            </span>)}

            {/* <span className="added_cart">
              <TickIcon />
              {item.isAddedToCart ? "Added to Cart" : "Add to Cart"}
            </span>
            <span
              className="cursor iconaddcart"
              aria-label="Remove from cart"
              onClick={() => removeCartIteme(item.token))}
            >
              <img src={remove} alt="Remove from cart" />
            </span> */}
            {/* <span
              className="cursor iconaddcart"
              aria-label="Add to cart"
              onClick={() => addCardHandleSelect(item.token)}
            >
              <img src={addcart} alt="Add to cart" />
            </span> */}
          </div>
        </div>
      </ListItem>
    ))
  }, [data,cartData]) // Only recompute when `data` changes

  return (
    <div className={classes.suggestion_box}>
      <div className={classes.suggestion_result}>
        <div className={classes.suggestion_result_right}>
          <p>{summaryData.resultsTotal} Results</p>
        </div>
        <div>
          <CustomeSlecter
            data={
              materials?.length > 0
                ? materials.map(sub => ({
                    label: sub.name,
                    value: sub.token,
                  }))
                : [] // Provide an empty array as fallback
            }
            title={"Material"}
            width={130}
            value={summaryData.slectmaterial}
            onChange={e => handleMaterialChange(e.target.value)}
          />
          <CustomeSlecter
            data={
              coatings?.length > 0
                ? coatings.map(sub => ({
                    label: sub.name,
                    value: sub.token,
                  }))
                : [] // Provide an empty array as fallback
            }
            title={"Finish"}
            width={130}
            value={summaryData.slectfinish}
            onChange={e => handleSubFinishChange(e.target.value)}
          />
        </div>
      </div>
      <Paper
        elevation={3}
        style={{
          maxWidth: "100%",
          marginTop: "5px",
          position: "relative",
          zIndex: 50,
          marginBottom: "20px",
          maxHeight: "384px",
          overflow: "auto",
        }}
      >
        <List>{renderedSuggestions}</List>
      </Paper>
    </div>
  )
}

export default Suggestions
