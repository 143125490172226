import React from "react"
import classes from "./RecentOrders.module.css"
import CarouselContainer from "../../components/UI/Carousel/Carousel"
import OrderCard from "../../components/UI/Card/OrderCard"

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
}

const RecentOrders = () => {
  return (
    <div>
      <h3 className={classes.title}>Recent Orders</h3>
      <CarouselContainer
        responsive={responsive}
        className="recenOrder"
        itemClass={"carouselItem"}
        showArrows={true}
      >
        <OrderCard />
        <OrderCard />
        <OrderCard />
        <OrderCard />
        <OrderCard />
        <OrderCard />
        <OrderCard />
      </CarouselContainer>
    </div>
  )
}

export default RecentOrders
