import React from "react"
import HomeContant from "../../features/Home/HomeContant"

const Home = () => {
  return (
    <div style={{ marginTop: "23.5px" }}>
      <HomeContant />
    </div>
  )
}

export default Home
