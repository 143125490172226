import React from "react"
import classes from "./HomeContant.module.css"
import Banner from "./Banner"
import RecentOrders from "./RecentOrders"
import Tools from "./Tools"
import FooterBanner from "./FooterBanner"

const HomeContant = () => {
  return (
    <>
      <Banner />
      {/* <RecentOrders /> */}
      <div className={classes.box_loyout_tools}>
        <Tools />
      </div>
      <div className={classes.footer_baner}>
        <FooterBanner />
      </div>
    </>
  )
}

export default HomeContant
