import { useState, useMemo, useEffect } from "react"
import classes from "./ShopNow.module.css"
import { useDispatch, useSelector } from "react-redux"
import {
  setSearchTerm,
  clearSuggestions,
  selectSuggestions,
  setMetaData,
  fetchList,
  clearAllData,
} from "../../services/storeSlice/shopNowSlice"

import TabBarWithRender from "../../components/UI/Tabs/Tabs"
import SearchingCategory from "../../features/ShopNow/SearchingFilter/Searching"
import SingleSearching from "../../features/ShopNow/SingeleSearching/Searching"
import SingleSearchingAll from "../../features/ShopNow/SingeleSearchingByAll/Searching"
import Suggestions from "../../features/ShopNow/Suggestions/Suggestions"
import useApiHttp from "../../hooks/ues-http"
import OrderSummary from "../OrderSummary"
import GetAddress from "../../features/ShopNow/Address/GetAddress"

const ShopNow = () => {
  const dispatch = useDispatch()
  const data = useSelector(state => state.shopNow)
  const steperStatus = data.setperStatus
  const [activeTab, setActiveTab] = useState(0)

  const suggestions = data.suggestions

  const {
    isLoading: metaDataLoading,
    success: metaDataSucces,
    error: metaDataError,
    sendRequest: metaDataRequest,
  } = useApiHttp()

  useEffect(() => {
    metaDataRequest(
      {
        url: `dealer/shop-now/drop-down`,
      },
      data => {
        dispatch(setMetaData(data.data))
      }
    )
  }, [])

  useEffect(() => {
    dispatch(fetchList())
  }, [])

  // Memoize the tabs array
  const tabs = useMemo(
    () => [
      {
        title: "Search By Category",
        render: () => (
          <div className={classes.category} style={{ marginBottom: "32rem" }}>
            <SearchingCategory Loading={metaDataLoading} />
            {suggestions.length > 0 ? (
              <Suggestions data={suggestions} />
            ) : (
              <div
                style={{
                  textAlign: "center",
                  background: "#fff",
                  marginTop: ".5rem",
                  padding: " 3px",
                  borderRadius: "5px",
                }}
              >
                No Data
              </div>
            )}
          </div>
        ),
      },
      {
        title: "Search by Item Code",
        render: () => (
          <div className={classes.category} style={{ marginBottom: "32rem" }}>
            <SingleSearching />
            {suggestions.length > 0 ? (
              <Suggestions data={suggestions} />
            ) : (
              <div
                style={{
                  textAlign: "center",
                  background: "#fff",
                  marginTop: ".5rem",
                  padding: " 3px",
                  borderRadius: "5px",
                }}
              >
                No Data
              </div>
            )}
          </div>
        ),
      },
      {
        title: "Search by Product",
        render: () => (
          <>
            <SingleSearchingAll />
            {suggestions.length > 0 ? (
              <Suggestions data={suggestions} />
            ) : (
              <div
                style={{
                  textAlign: "center",
                  background: "#fff",
                  marginTop: ".5rem",
                  padding: " 3px",
                  borderRadius: "5px",
                }}
              >
                No Data
              </div>
            )}
          </>
        ),
      },
      // {
      //   title: "Recent Orders",
      //   render: () => <></>,
      // },
      // {
      //   title: "In-Stock Products",
      //   render: () => <></>,
      // },
    ],
    [suggestions] // tabs only change when `suggestions` changes
  )

  const onTabClick = index => {
    dispatch(clearAllData())
    setActiveTab(index)
  }

  return (
    <div className={classes.shopnow}>
      {steperStatus === 0 && (
        <>
          <h3 className={classes.title}>
            Explore 5,000+ Wide range of Products
          </h3>
          <p className={classes.sub_title}>
            Explore a wide range of products by category, subcategory, finish,
            and material type to find the perfect match for your needs.
          </p>
          <TabBarWithRender
            tabs={tabs}
            onTabClick={onTabClick}
            activeTab={activeTab}
          />
        </>
      )}
      {steperStatus === 1 && (
        <div style={{ marginBottom: " 10rem" }}>
          <GetAddress />
        </div>
      )}
      {steperStatus === 2 && (
        <div sstyle={{ marginBottom: " 40rem" }}>
          <OrderSummary />
        </div>
      )}
    </div>
  )
}

export default ShopNow
