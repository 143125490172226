import React, { useEffect } from "react"
import calsses from "./Summary.module.css"
import CustomButton from "../../components/UI/Button/Button"
import { useDispatch, useSelector } from "react-redux"
import remove from "../../assets/image/svg/remove.svg"
import {
  fetchList,
  fetchRemoveList,
  updateCartItems,
  updateQtyDate,
  setSteperVlue,
} from "../../services/storeSlice/shopNowSlice"
import CustomDatePicker from "../../components/UI/DatePicker/DatePicker"
import Qty from "../../components/UI/Qty/Qty"
import dayjs from "dayjs"
import { currentDate } from "../../utils/helper"
const Summary = () => {
  const dispatch = useDispatch()
  const data = useSelector(state => state.shopNow)


  const cart = data?.cartData?.cart
  const cartItem = data?.cartData?.cart_items

  const removeCartIteme = token => {
    dispatch(fetchRemoveList({ body: { cart_item_token: token } }))
      .unwrap()
      .then(() => {
        dispatch(fetchList())
      })
  }

  useEffect(() => {
    if (data.updateQtyDateStatus === "succeeded") {

      dispatch(fetchList())
    }
  }, [data.updateQtyDateStatus])

  const handlerContinue = () => {
    dispatch(setSteperVlue(1))
  }

  return (
    <div className={calsses.sammary}>
      <div className={calsses.sammary_card}>
        <div className={calsses.sammary_card_left}>
          <h3>Summary</h3>
          <p>
            {cartItem?.length && cartItem?.length ? cartItem?.length : 0}{" "}
            Products
          </p>
        </div>
        <div className={calsses.sammary_card_right}>
          <div className={calsses.card}>
            <div className={calsses.card_contant}>
              <p>Order Total Value</p>
              <label className={calsses.card_value}>
                {cart && (
                  <span className="orginal_price">
                    ₹{" "}
                    {cart?.total_amount
                      ? (cart?.total_amount).toLocaleString("en-IN")
                      : 0}
                  </span>
                )}
                {cart &&
                  !isNaN(cart?.total_item_mrp + cart?.total_tax_amount) && (
                    <span className="line_through_price">
                      ₹{" "}
                      {(
                        cart?.total_item_mrp + cart?.total_tax_amount
                      ).toLocaleString("en-IN")}
                    </span>
                  )}
              </label>
            </div>
            <CustomButton
              variant="contained"
              customColor="white"
              customBgColor="#32349b"
              custmstyle={{
                padding: "5px 12px",
                with: "100px",
                borderRadius: "6px",
              }}
              onClick={handlerContinue}
            >
              Continue
            </CustomButton>
          </div>
        </div>
      </div>
      <div className={calsses.card_item}>
        {cartItem?.length > 0 && (
          <>
            {cartItem.map((item, index) => (
              <div className={calsses.sammary_card_item}>
                <div className={calsses.summary_detail}>
                  <div>
                    <p className={calsses.sno}>{index + 1}.</p>
                  </div>
                  <div>
                    <h3>{item.product_code}</h3>
                    <h5>{item.name}</h5>
                    <p>{item.sub_name}</p>
                  </div>
                </div>
                <div>
                  <div className={calsses.sammary_card_item_right}>
                    <label
                      className={`label_widgets ${
                        item.stock_status === 1 ? "in_stock" : "out_stock"
                      }`}
                    >
                      {item.stock_status === 1 ? "In Stock" : "Out of Stock"}
                    </label>
                    <div
                      style={{
                        width: "100px",
                        alignContent: "end",
                        paddingTop: "8px",
                      }}
                    >
                      <Qty
                        type={"number"}
                        label={"Qty"}
                        onChange={e => {
                          dispatch(
                            updateCartItems({
                              index,
                              value: {
                                ...item,
                                product_quantity: e.target.value,
                              },
                            })
                          )
                        }}
                        onBlur={e => {
                          dispatch(
                            updateCartItems({
                              index,
                              value: {
                                ...item,
                                product_quantity:
                                  e.target.value < item.moq
                                    ? item.moq
                                    : e.target.value,
                              },
                            })
                          )
                          dispatch(
                            updateQtyDate({
                              body: {
                                cart_item_token: item.cart_item_token,
                                quantity:
                                  e.target.value < item.moq
                                    ? item.moq
                                    : e.target.value,
                                requested_date: item.requested_date,
                              },
                            })
                          )
                        }}
                        value={item.product_quantity}
                      />
                    </div>
                    <div
                      style={{
                        alignContent: "end",
                        width: "200px",
                      }}
                    >
                      <CustomDatePicker
                        width="50px"
                        label={"Need Date"}
                        format="DD-MM-YYYY"
                        minDate={dayjs(currentDate())}
                        value={dayjs(item.requested_date)}
                        onChange={value => {
                          dispatch(
                            updateCartItems({
                              index,
                              value: {
                                ...item,
                                requested_date:
                                  dayjs(value).format("YYYY-MM-DD"),
                              },
                            })
                          )
                          dispatch(
                            updateQtyDate({
                              body: {
                                cart_item_token: item.cart_item_token,
                                quantity: item.product_quantity,
                                requested_date:
                                  dayjs(value).format("YYYY-MM-DD"),
                              },
                            })
                          )
                        }}
                      />
                    </div>
                    <label className={calsses.price}>
                      <span className="count" style={{ fontSize: "24px" }}>
                        ₹ {item.total_item_rate.toLocaleString("en-IN")}
                      </span>
                      <span
                        className="line_through_price"
                        style={{ fontSize: "16px" }}
                      >
                        ₹ {item.total_mrp.toLocaleString("en-IN")}
                      </span>
                    </label>

                    <span
                      className="cursor iconaddcart"
                      aria-label="Remove from cart"
                      onClick={() => removeCartIteme(item.cart_item_token)}
                    >
                      <img
                        src={remove}
                        alt="Remove from cart"
                        style={{ width: "20px" }}
                      />
                    </span>
                  </div>
                </div>
              </div>
            ))}
            <div
              className={calsses.total_card}
              style={{ paddingBottom: "3rem" }}
            >
              <div className={calsses.tax}>
                <div className={calsses.flex}>
                  <p>Order Total Value</p>
                  <p>
                    ₹{" "}
                    {cart?.total_item_amount
                      ? (cart?.total_item_amount).toLocaleString("en-IN")
                      : 0}
                  </p>
                </div>
                <div className={calsses.flex}>
                  <p>Total Tax ({cart?.total_tax_percentage || 0}%)</p>
                  <p>
                    ₹{" "}
                    {cart?.total_tax_amount
                      ? (cart?.total_tax_amount).toLocaleString("en-IN")
                      : 0}
                  </p>
                </div>
              </div>
              <div className={calsses.total}>
                <div className={calsses.flex}>
                  <p>Total Amount</p>
                  <p>
                    {" "}
                    ₹{" "}
                    {cart?.total_amount
                      ? (cart?.total_amount).toLocaleString("en-IN")
                      : 0}
                  </p>
                </div>
              </div>
              <CustomButton
                variant="contained"
                customColor="white"
                customBgColor="#32349b"
                custmstyle={{ padding: "7px 7px", width: "100%" }}
                onClick={handlerContinue}
              >
                Continue
              </CustomButton>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default Summary
